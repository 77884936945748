<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add user
        <div class="card-header-actions">
          <a class="card-header-action" href="super-user" rel="noreferrer noopener">
            <small class="text-muted">back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
          <div class="loading" v-if="isLoading == true">
              <div class="sk-three-bounce">
                  <div class="sk-child sk-bounce1"></div>
                  <div class="sk-child sk-bounce2"></div>
                  <div class="sk-child sk-bounce3"></div>
              </div>
          </div>
        <b-row>
          <b-col>
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" v-on:reset.prevent="onReset" novalidate>
              <b-form-group id="userNameInputGroup3"
                            label="Name"
                            label-for="userName">
                <b-form-input id="userName"
                              type="text"
                              :class="{ 'is-invalid' : $v.form.name.$errors, 'is-valid' : !$v.form.name.$invalid}"
                              v-model.trim="$v.form.name.$model"
                              :state="chkState('name')"
                              aria-describedby="nameFeedback"
                              placeholder="Name"
                              autocomplete='username' />
                <b-form-invalid-feedback id="nameFeedback" >
                  <span v-if="!$v.form.name.required">- Nama User tidak boleh kosong!</span>
                  <span v-if="!$v.form.name.isUnique">- Nama User tidak boleh sama dengan data sebelumnya!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="emailInputGroup4"
                            label="Email"
                            label-for="email">
                <b-form-input id="email"
                              type="email"
                              :class="{ 'is-invalid' : $v.form.email.$errors, 'is-valid' : !$v.form.email.$invalid}"
                              v-model.trim="$v.form.email.$model"
                              :state="chkState('email')"
                              aria-describedby="emailFeedback"
                              placeholder="Email"
                              autocomplete='email' />
                <b-form-invalid-feedback id="emailFeedback" >
                  <span v-if="!$v.form.email.required">- Email User tidak boleh kosong!</span>
                  <span v-if="!$v.form.email.email">- Email User tidak valid!</span>
                  <span v-if="!$v.form.email.isUnique">- Email User tidak boleh sama dengan data sebelumnya!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row class="mb-4">
                <b-col>
                  <p style="font-weight:bold">Role</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3" v-for="(roles, index) in role" :key="index">
                  <b-form-group>
                    <b-form-checkbox-group stacked id="basicCheckboxes" name="Checkboxes" v-model="roleModel" :plain="true">
                      <b-form-checkbox :value="role[index].id"  @change="dataRole(role,index, check, $event) " :checked="check">{{ role[index].name }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col>
                  <p style="font-weight:bold">Permission</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3" v-for="(permissions, index) in permission" :key="index">
                  <b-form-group>
                    <b-form-checkbox-group stacked id="basicCheckboxes" name="Checkboxes" v-model="permissionName" :plain="true">
                      <b-form-checkbox :value="permission[index].id">{{ permission[index].name }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, email } from "vuelidate/lib/validators"


export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        name: "",
        email: "",
      },
      role: "",
      roles: [],
      roleModel: "",
      permission: "",
      permissions: [],
      row: [],
      col: [],
      multipleRequest: [],
      splitSelect: [],
      permissionName: [],
      rowRole: [],
      event: "",
      evn: [],
      check: false,
      name: '',
      email: '',
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.post(`user-name/${value}`, {
                names: this.name
              }).then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
      email: {
        required,
        email,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.post(`user-email/${value}`, {
                emails: this.email
              }).then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
    }
  },
  methods: {
    dataRole(role, index, check, e){
      check = true;
      if (e != null) {
        this.multipleRequest.push(role[index].id);
        for (let i = 0; i < this.multipleRequest.length; i++) {
          this.getListPermission(i, this.multipleRequest);
        }
      }else{
        this.permissionName = null
        for (let i = 0; i < this.multipleRequest.length; i++) {
          if (this.multipleRequest[i] == role[index].id) {
            this.multipleRequest.splice(i, 1);
          }
          this.getListPermission(i, this.multipleRequest);
        }
      }
    },
    getListPermission(i, ) {
      if (this.multipleRequest.length <= 1) {

        this.$http.post(`role-has-permissions`, {
          role: this.multipleRequest[i],
          user_id: this.$route.params.id
        }).then((result) => {
          this.permissionName = result.data.permission;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      }else{

        this.$http.post(`role-has-permissions`, {
          role: this.multipleRequest,
          user_id: this.$route.params.id
        }).then((result) => {
          this.permissionName = result.data.permission;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      }
    },
    loadRoleAndPermissions() {

      this.$http.get(`role-permission`)
      .then((result) => {
        this.role = result.data.role;
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
    },
    loadPermission() {

      this.$http.get(`permission-data`, {
        params: {
            permission: this.permissions
        }
      }).then((result) => {
      this.permission = result.data.permission;
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
    },
    onSubmit() {
      this.validate()
      this.isLoading = true
      this.$http.post(`user-update/` + this.$route.params.id, {
        roles: this.roleModel,
        permissions: this.permissionName,
        name: this.form.name,
        email: this.form.email
      }).then(() => {
        this.isLoading = false
        this.$router.push("/super-user");
        this.$toasted.success('User successfully updated!')
      }).catch((error) => {
        if (error.response) {
          this.isLoading= false
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
          if(this.errors.status == 422) {
            this.$toasted.error('Please fill out the form that must be required')
            this.errors.message = error.response.data.meta.message;
          }
        }
      })
    },
    onReset() {
      // Reset validation
      this.form = Object.assign({},)
      this.submitted = false
      this.$nextTick(() => {
        this.$v.$reset()
        this.feedBack = 'secondary'
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
  created () {

    this.$http.put(`user-data/` + this.$route.params.id)
    .then((result) => {

      this.roleModel = result.data.roles;
      for (let i = 0; i < this.roleModel.length; i++) {
        this.multipleRequest.push(this.roleModel[i]);
      }
      this.permissionName = result.data.permissions;
      this.form.name = result.data.user.name;
      this.name = this.form.name;
      this.form.email = result.data.user.email;
      this.email = this.form.email;
    }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
  },
  mounted() {
    this.loadRoleAndPermissions();
    this.loadPermission();
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
